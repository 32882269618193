import React from "react";
import { useSelector } from "react-redux";
import { SystemStyleObject } from "@styled-system/css";

import { BoxContainer } from "@opr-finance/component-box-container";
import { Font, Text } from "@opr-finance/component-fonts";

import { RecentNewsItem } from "./RecentNewsItem";
import { FeatureNewsState } from "../types/contentful";

export type RecentNewsProps = {
    translation: {
        title: string;
        readMore: string;
    };
    styleConfig?: NewsStyles;
};

type NewsStyles = {
    heading: SystemStyleObject;
    newsWrapper: SystemStyleObject;
    headlines: SystemStyleObject;
    headlinesModal: SystemStyleObject;
    newsDate: SystemStyleObject;
    newsDateModal: SystemStyleObject;
    news: SystemStyleObject;
    newsModal: SystemStyleObject;
    seeMore: SystemStyleObject;
    modalTitle: SystemStyleObject;
    titleText: SystemStyleObject;
    modalOverlay: SystemStyleObject;
    modalContent: SystemStyleObject;
    modalCloseIcon: SystemStyleObject;
};

export function RecentNews(props: RecentNewsProps) {
    const news = useSelector((state: FeatureNewsState) => state.news.news);
    // const news = allNews && allNews.length >= 2 ? allNews.slice(0, 2) : allNews;
    if (props.styleConfig) {
        return (
            <>
                <Font styleConfig={{ root: props.styleConfig.heading }}>
                    {props.translation.title}
                </Font>
                {news !== null &&
                    news.map((item, index) => (
                        <RecentNewsItem
                            styles={{
                                newsWrapper: props.styleConfig?.newsWrapper ?? {},
                                headlines: props.styleConfig?.headlines ?? {},
                                headlinesModal: props.styleConfig?.headlinesModal ?? {},
                                newsDate: props.styleConfig?.newsDate ?? {},
                                newsDateModal: props.styleConfig?.newsDateModal ?? {},
                                news: props.styleConfig?.news ?? {},
                                newsModal: props.styleConfig?.newsModal ?? {},
                                seeMore: props.styleConfig?.seeMore ?? {},
                                modalTitle: props.styleConfig?.modalTitle ?? {},
                                titleText: props.styleConfig?.titleText ?? {},
                                modalOverlay: props.styleConfig?.modalOverlay ?? {},
                                modalContent: props.styleConfig?.modalContent ?? {},
                                modalCloseIcon: props.styleConfig?.modalCloseIcon ?? {},
                            }}
                            readMoreLabel={props.translation.readMore}
                            modalTitle={props.translation.title}
                            {...item}
                            key={`item-${item.publicationDate}`}
                        />
                    ))}
            </>
        );
    }

    return (
        <BoxContainer
            paddingBottom={["16px", "0px"]}
            height={["auto", "500px"]}
            overflow={"scroll"}>
            <Text variant="bodyTitle" paddingBottom={"8px"}>
                {props.translation.title}
            </Text>
            {news !== null &&
                news.map((item, index) => (
                    <RecentNewsItem
                        modalTitle={props.translation.title}
                        readMoreLabel={props.translation.readMore}
                        {...item}
                        key={`item-${item.publicationDate}`}
                    />
                ))}
        </BoxContainer>
    );
}

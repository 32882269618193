import * as VP from "@opr-finance/api-definitions";
import { T_AutomateESigningPayload } from "../types/general";

export function mapToAutomateESigningPayload(
    applicationResponse: VP.components["schemas"]["ApplicationV8Response"],
    companyInfo: { organizationNumber?: string; companyName?: string }
): T_AutomateESigningPayload {
    return {
        sme: {
            smeId: applicationResponse.smeId,
            organizationNumber: companyInfo.organizationNumber || "",
            companyName: companyInfo.companyName || "",
        },
        application: {
            id: applicationResponse.id || "",
            desiredAmount: applicationResponse.desiredAmount || 0,
            applicationChannel: applicationResponse.applicationChannel,
            applicationState: applicationResponse.applicationState || "",
        },
    };
}

export async function automateESigning(data: T_AutomateESigningPayload) {
    const brokerProxyProductName = "flex-cli";
    const response = await fetch(
        `${process.env.REACT_APP_FRENDS_BROKER_PROXY_URL}/v1/opr-finance/application/${brokerProxyProductName}`,
        {
            method: "POST",
            headers: {
                "content-type": "application/json",
                "X-ApiKey": `${process.env.REACT_APP_BROKER_PROXY_API_KEY}`,
            },
            body: JSON.stringify(data),
        }
    );
    const responseData = await response.json();
    return responseData;
}
